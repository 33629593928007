import repositories from '@/repositories'

export default {
  addCompany(company) {
    return repositories.UserRepository.addCompany(company).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  addSchool(school) {
    return repositories.UserRepository.addSchool(school).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  addStudent(student) {
    return repositories.UserRepository.addStudent(student).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  register(data) {
    return repositories.UserRepository.register(data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
}
