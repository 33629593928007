import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/school-fund-export'

export default {
  export(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
}
