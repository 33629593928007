import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = 'api/v1/library'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  listParams(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/category/${uuid}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  getParams(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/sort/${uuid}${paramsString}`)
  },
  add(library) {
    return http.post(`${resource}`, library)
  },
  update(library, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, library)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },

}
