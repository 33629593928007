import http from '@axios'

const resource = '/api/v1/user/generate-qr-user'

export default {

  add(generate) {
    return http.post(`${resource}`, generate)
  },
}
