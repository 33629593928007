import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = 'api/v1/kadis/slider'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(slider) {
    return http.post(`${resource}`, slider, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(slider, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, slider, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
