import http from '@axios'

const resource = '/api/v1/user/pdf-qrcode'

export default {

  print(uuid) {
    return http.get(`${resource}/${uuid}`, {
      responseType: 'blob',
      contentType: 'aplication/pdf',
    })
  },
}
