import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = 'api/v1/library'

export default {
  listParams(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/sort/${uuid}${paramsString}`)
  },
}
