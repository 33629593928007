import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/notification/${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/notification/${uuid}`)
  },
  send(data) {
    return http.post(`${resource}/send-notification`, data)
  },
  sendAll(data) {
    return http.post(`${resource}/send-notification-multi`, data)
  },
  add(data) {
    return http.post(`${resource}/notification`, data)
  },
  update(data, uuid) {
    return http.post(`${resource}/notification/${uuid}`, data)
  },
  destroy(uuid) {
    return http.delete(`${resource}/notification/${uuid}`)
  },
}
