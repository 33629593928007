import { ref } from '@vue/composition-api'

export default () => {
  const resolveNavItemComponent = item => {
    // if( !ability.can('read', item.title) ||
    //     ability.can('write',item.title) ||
    //     ability.can('update',item.title) ||
    //     ability.can('delete',item.title))
    // {
    //   return null
    // }
    if (item.subheader) return 'vertical-nav-menu-section-title'
    if (item.children) return 'vertical-nav-menu-group'

    return 'vertical-nav-menu-link'
  }

  const isMouseHovered = ref(false)

  return {
    isMouseHovered,
    resolveNavItemComponent,
  }
}
