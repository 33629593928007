const user = JSON.parse(localStorage.getItem('user')) ?? []
// const abilities = user.user?.ability ?? []
// import announcements from './announcement'
// import dashboard from './dashboard'
// import library from './library'
// import locations from './location'
// import packet from './packet'
// import violations from './violation'
// const ability = localStorage.getItem('userAbility')

export default () => {
  const abilities = JSON.parse(localStorage.getItem('userAbility')) ?? []
  const elements = []
  abilities.forEach(element => {
    const push = element.subject.toLowerCase()
    // eslint-disable-next-line import/no-dynamic-require
    if (push !== 'appuserview' && push !== 'appuserfaq') {
      elements.push(require(`./${push}`).default ?? [])
    }
  })

  return [...elements]
}

// export default [
//   ...dashboard,
//   // ...users,
//   // ...students,
//   // ...school,
//   // ...companies,
//   ...locations,
//   // ...leaderboards,
//   // ...attendances,
//   // ...cbt,
//   // ...scores,
//   // ...elearning,
//   // ...evoting,
//   // ...functionary,
//   // ...referency,
//   ...violations,
//   ...library,
//   // ...lessons,
//   ...packet,
//   ...announcements,
// ]
