const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
const data = {
  students: [
    {
      name: 'Agus',
      email: 'agus123@gmail.com',
      number: '082121211144',
      course: 'Teknik',
    },
    {
      name: 'Arif',
      email: 'arif123@gmail.com',
      number: '082264611144',
      course: 'Matematika',
    },
    {
      name: 'Ahmad',
      email: 'ahmad123@gmail.com',
      number: '082121211144',
      course: 'Kimia',
    },
    {
      name: 'Andi',
      email: 'andi123@gmail.com',
      number: '082321901144',
      course: 'Olahraga',
    },
  ],
}
