import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const baseDomain = process.env.VUE_APP_API_BASE_URL
const baseURL = `${baseDomain}`

const axiosEnv = axios.create({
  baseURL,
})

axiosEnv.interceptors.request.use(req => {
  if (req.headers.authorization === undefined) {
    req.headers.authorization = `Bearer ${store.getters['session/token']}`
    req.headers['Access-Control-Allow-Origin'] = '*'
  }

  return req
})

axiosEnv.interceptors.response.use(
  res => Promise.resolve(res),
  err => {
    let errorMessage = err.response.data.message ?? ''
    try {
      errorMessage = err.response.data.result.message.includes(':')
        ? err.response.data.result.message.split(':')[1]
        : err.response.data.result.message
    } catch (error) {
      errorMessage = err.response.data.message ?? 'Something Wrong'
    }

    if (err.message === 'Network Error') {
      store.dispatch('snackbar/showSnackbar', {
        text: 'You are not connected to the server',
        color: 'error',
      })

      return Promise.reject(err.response.data.result)
    }
    if (err.response.status === 401) {
      store.dispatch('snackbar/showSnackbar', {
        text: `${errorMessage}  Redirected to login..`,
        color: 'error',
      })
      localStorage.removeItem('accessToken')

      // Remove user & Ability from localStorage
      localStorage.removeItem('user')
      localStorage.removeItem('userAbility')

      // // Redirect to login page
      window.location.href = '/'

      return Promise.reject(err.response.data.result)
    }

    switch (err.response.status) {
      case 500:
        store.dispatch('snackbar/showSnackbar', {
          text: errorMessage,
          color: 'error',
        })

        return Promise.reject(err.response.data.result)
      case 400:
        err.response.data.result.message.forEach(item => {
          item.errors.forEach(err => {
            store.dispatch('snackbar/showSnackbar', {
              text: `${err} - ${item.values.username}`,
              color: 'error',
            })
          })
        })

        return Promise.reject(err.response.data.result)
      default:
        store.dispatch('snackbar/showSnackbar', {
          text: errorMessage,
          color: 'error',
        })

        return Promise.reject(err.response.data.result)
    }
  },
)

Vue.prototype.$http = axiosEnv
export default axiosEnv
