import repositories from '@/repositories'

export default {
  allowNotification(token) {
    return repositories.FcmRepository.allowNotification(token).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
}
