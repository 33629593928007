export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
  {
    action: 'read',
    subject: 'AppUserView',
  },
  {
    action: 'read',
    subject: 'AppUserSettings',
  },
  {
    action: 'read',
    subject: 'AppUserFaq',
  },
  {
    action: 'read',
    subject: 'AppUserNotification',
  },
]

export const SuperAdminAbility = [
  {
    action: 'read',
    subject: 'Dashboard',
  },
  {
    action: 'read',
    subject: 'DashboardStudent',
  },
  {
    action: 'read',
    subject: 'Location',
  },
  {
    action: 'read',
    subject: 'Province',
  },
  {
    action: 'read',
    subject: 'Regency',
  },
  {
    action: 'read',
    subject: 'District',
  },
  {
    action: 'read',
    subject: 'Village',
  },
  {
    action: 'read',
    subject: 'Packet',
  },
  {
    action: 'read',
    subject: 'Library',
  },
  {
    action: 'read',
    subject: 'UserList',
  },
  {
    action: 'read',
    subject: 'UserAdd',
  },
]

export const _ = undefined
