import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/export-attendance-all'

export default {

  printRecap(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
}
