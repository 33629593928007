import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/bookmark-forum'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  edit(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  add(bookmark) {
    return http.post(`${resource}`, bookmark)
  },
  update(bookmark, uuid) {
    return http.put(`${resource}/${uuid}`, bookmark)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
  getBookmark(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}${paramsString}`)
  },
}
