import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/archive-question'
const exportResource = '/api/v1/archive-question/many-export'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(archive) {
    return http.post(`${resource}`, archive, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(archive, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, archive, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
  export(archive) {
    return http.post(`${exportResource}`, archive)
  },
}
