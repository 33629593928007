import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/kadis/greeting'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(greeting) {
    return http.post(`${resource}`, greeting)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(greeting, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, greeting)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
