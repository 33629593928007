import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/kacabdis'
const resource2 = '/api/v1/kacabdis/detail-school'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  getDetailSchool(params = {}, uuid) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource2}/${uuid}${paramsString}`)
  },
  add(hob) {
    return http.post(`${resource}`, hob)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(hob, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, hob, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
